import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Первое знакомство`}</h2>
    <p>{`Добро пожаловать! Industrator – маркетплейс индустриальной продукции и технологий. Наши цифровые решения способствуют объединению и сотрудничеству производителей, поставщиков и заказчиков промышленной продукции.`}</p>
    <h2>{`О проекте`}</h2>
    <p>{`Industrator предоставляет специалистам промышленных предприятий площадку для подбора необходимой продукции и возможность связаться с поставщиком, для производителей и поставщиков – возможность для продвижения своей продукции и дополнительный канал продаж. Мы работаем по модели DBS (Delivery by Seller) – предоставляем поставщику возможность размещать и рекламировать свою продукцию, поставщик – самостоятельно получает оплату, хранит, упаковывает и доставляет продукцию.`}</p>
    <p>{`В настоящее время проект находится на стадии A/B-тестирования, что означает большое количество обновлений функционала, возможное возникновение ошибок и некоторое снижение производительности.`}</p>
    <p>{`Если у вас есть пожелания или предложения по развитию проекта – пожалуйста, `}<a parentName="p" {...{
        "href": "http://asdasd"
      }}>{`напишите нам`}</a>{`.`}</p>
    <h2>{`Возможности`}</h2>
    <p>{`Для разных категорий пользователей предусмотрен различный набор функционала и возможностей, для изучения более подробной информации, пожалуйста, выберите соответствующий раздел:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Производителям`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Поставщикам`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Предприятиям`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      